import React from 'react'

import './assets/scss/application.scss'
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'
import { Patients } from './pages/patients/Patients'
import { Appointments } from './pages/appointments/Appointments'
import { NavigationProvider } from './pages/auth/NavigationContext'
import PrivateRoute from './pages/auth/PrivateRoute'
import { Login } from './pages/auth/Login'
import { Billing } from './pages/reports/Billing'
import { Layout } from './layouts/Layout'
import { Config } from './pages/config/Config'


export const App = () => {
  return (
    <BrowserRouter future={{ v7_startTransition: true, v7_relativeSplatPath: true, }}>
      <NavigationProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<PrivateRoute element={<Layout />} role='doctor' />}>
            <Route path="/patients" element={<Outlet />}>
              <Route index element={<Patients.Index />} />
              <Route path=":id" element={<Patients.Show />} />
            </Route>
            <Route path='/reports' element={<Outlet />}>
              <Route path='billing' element={<Billing />} />
            </Route>
            <Route path="appointments" element={<Appointments.Index />} />
            <Route path='/config' element={<Config.Index />} />
          </Route>
        </Routes>
      </NavigationProvider>
    </BrowserRouter>
  )
}